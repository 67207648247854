<template>
  <div>
    <Header />
 <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="twodaoi()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/aoi/2daoi.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">2D AOI</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="threedaoi()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/aoi/3daoi.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">3D AOI </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../components/Header.vue";

import Footer from "../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    twodaoi(){
      window.location.href = "https://www.sakicorp.com/en/product/2daoi/";
    
    },
     threedaoi(){
      window.location.href = "https://www.sakicorp.com/en/product/3daoi/";
      
    },
  },
};
</script>